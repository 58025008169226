<template>
  <div style="width:1200px;margin:0 auto; padding-top:30px">
    <!-- <div class="div_center">
      <div style="width: 1200px;margin: 0 auto;text-align: center" class="qytj">
        <span>科技项目评测系统</span>
        <span>企业手中的科技项目申报指南</span>
        <span style="font-size: 31px">快速获取科学、精确的评测报告</span>
        <span>一键诊断高企通过率！轻松申报政府百万资金项目！</span>
        <span @click="goExam()" style="cursor: pointer">立即申报</span>
      </div>
    </div>
    <div class="center" style="height: 940px;">
      <div style="
    margin-top: 80px;
    margin-bottom: 50px;
  font-size: 28px;
    color: rgb(51, 51, 51);
">便携流程
      </div>
      <div class="bxlc">

        <div style="text-align: center;padding-top: 40px">
          <img :src="jbxx" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">填写基本信息</span>
        </div>
        <span>>>></span>

        <div style="text-align: center;padding-top: 40px">

          <img :src="cqzk" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">填写企业知识产权状况</span>
        </div>
        <span>>>></span>
        <div style="text-align: center;padding-top: 40px">

          <img :src="glzk" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">确认企业财务及研发管理状况</span>
        </div>
        <span>>>></span>
        <div style="text-align: center;padding-top: 40px">

          <img :src="csbg" alt=""><br>
          <span style="font-size: 16px;color: black;font-weight: bold;line-height: 80px">提交生成测评报告</span>
        </div>
      </div>
      <div class="center" style="height: 600px;display: flex">
        <div style="height: 100%;width: 600px;display: flex;justify-content: space-between;align-items: center">
          <img :src="computer" alt="" style="">
          <img :src="arrow2"></img>
        </div>
        <div style="height: 100%;width: 600px;padding-top: 130px;padding-left: 40px">
          <div style="font-size: 28px;font-weight: bold;margin-bottom: 10px">产品简介</div>
          <div style="font-size: 14px;line-height: 30px">企业科技项目测评系统通过便捷的输入与反馈模式为企业提供强关联性的科技项目的申报指引、条件符合度、评分估算、解决方案对接等功能。<br>
            系统基于海量的政策梳理与格式化建模企业数据采集、加工、画像，利用大数据技术进行双向匹配并自动产出评测报告，成为企业申报项目的高效引导辅助工具。
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 400px; background-color: rgb(248, 250, 252); ">
      <div class="center" style="height: 400px">
        <Row>
          <Col span="12">
            <div style="height: 400px;width: 100%;padding: 100px 0" class="gqcp">
              <span style="font-size: 28px;color: black;font-weight: bold">高企测评</span>
              <span style="font-size: 20px;color: black">作为现阶段主打特色服务国家高新技术企业认定测评功能已全新上线！</span>
              <span
                style="font-size: 14px">国家高新技术企业是中国高科技企业最高荣誉之一，获评“国家高新技术企业”的公司不仅能收获国家级资质认证硬招牌取得丰厚的资金补贴还可畅享税收优惠等政策福利</span>
            </div>
          </Col>
          <Col span="12">
            <div style="display: flex;align-items: center;justify-content: center;padding-top: 60px">
              <img :src="lazy" alt="">
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div style="width: 100%;height: 630px;position: relative" class="anli">
      <div class="center" style="height: 630px;display: flex">
        <div style="width: 350px;height: 100%;padding-right: 40px">
          <span style="display: block;margin-top: 150px;margin-bottom: 55px;font-size: 28px">成功案例</span>
          <span style="display: inline-block;font-size: 14px;line-height: 25px">江苏省某制造型企业，成立时间为2014年12月，有自主知识产权，该公司2016年可加计的研发费用100万，2017年可加计的研发费用为300万元，2017年应纳税所得额为400万。其可获相关的税收优惠和科技项目支持为：</span>
        </div>
        <div style="width: 850px;height: 100%;background-color: #0072dc;padding-left: 70px;padding-top: 68px">
          <div class="sszc">税收政策</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;按照正常25%所得税税率&nbsp;&nbsp; &nbsp;
            所得税是：400*25%=100万
          </div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;既是高新技术企业，按照高新技术企业研发费用175%加计扣除 &nbsp;
            &nbsp; 所得税是：（400-300*75%）*15%=26.25
            <br>万
          </div>
          <div style="font-size: 14px;color: white;line-height: 30px;margin-top: 40px;margin-bottom: 50px">
            通过认定后，享受高新技术企业优惠税率当年可节企业所得税40万；研发费用进行加计扣除后，还可节企业所得税26.25万，累计节企业所得税66.25万
          </div>
          <div class="sszc" style="width: 150px">科技项目支持</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;高新技术企业奖励：主流城市50-200万不等；</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;研发奖补项目：按照研发费用加计扣除增长10%予以补助；</div>
          <div style="font-size: 14px;color: white;line-height: 30px">>>&nbsp;科技类项目优先立项，另有不同数额直接现金奖励。</div>
        </div>
      </div>
      <div style="position: absolute;background-color:#0072dc;top: 0;right: 0;height: 100% "
           :style="{'width':border_width}"></div>
    </div>
    <div style="height: 310px;text-align: center;width: 100%;">
      <div class="center" style="height: 310px;padding-top: 100px">
        <div class="bottom_div" style="color: black;font-weight: bold">【更多测评】</div>
        <div class="bottom_div">农业高新企业测评、小巨人企业测评、……</div>
        <div class="bottom_div">即将开放！</div>
      </div>

    </div>
    <div>-->
    <div class="exam-banner">
      <div class="btn-1 go-exam-btn" @click="goExam"></div>
    </div>
    <div style="padding:30px 0;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);">
      <div style="font-size: 20px;color: #333333;text-align:center;margin-bottom:30px">快速获取科学、精确的评测报告</div>
      <div class="flex-align-center" style="padding-left: 100px">
        <div class="flex-col-center">
          <img src="@/assets/image/index/exam-icon-1.png" style="width:42px">
          <div class="exam-step-txt">填写基本信息</div>
        </div>
        <div class="exam-dot">............</div>
        <div class="flex-col-center">
          <img src="@/assets/image/index/exam-icon-2.png" style="width:42px">
          <div class="exam-step-txt" style="font-size: 16px;color: #333333;">填写企业知识产权状况</div>
        </div>
        <div class="exam-dot">............</div>
        <div class="flex-col-center">
          <img src="@/assets/image/index/exam-icon-3.png" style="width:42px">
          <div class="exam-step-txt">确认企业财务及研发管理状况</div>
        </div>
        <div class="exam-dot">............</div>
        <div class="flex-col-center">
          <img src="@/assets/image/index/exam-icon-4.png" style="width:42px">
          <div class="exam-step-txt">提交生成测评报告</div>
        </div>
      </div>
    </div>
    <div style="margin:100px 0">
      <div style="font-size: 30px;color:#333333;text-align:center;margin-bottom:40px">科技项目评测系统</div>
      <div class="flex-between">
        <img src="@/assets/image/index/exam-sys-1.png" style="wdith:423px"/>
        <div class="exam-sys">
          <div style="font-size: 24px;font-weight: bold;line-height: 41px;">系统简介</div>
          <div style="font-size: 16px;line-height:33px;margin:20px 0;">
            企业科技项目测评系统通过便捷的输入与反馈模式为企业提供强关联性的科技项目的申报指引、条件符合度、评分估算、解决方案对接等功能。系统基于海量的政策梳理与格式化建模企业数据采集、加工、画像，利用大数据技术进行双向匹配并自动产出评测报告，成为企业申报项目的高效引导辅助工具。
          </div>
          <div class="go-exam-btn-g" @click="goExam">立即体验</div>
        </div>
      </div>
    </div>
    <div class="flex-between" style="margin-top:50px">
      <div style="width:598px">
        <div style="font-size: 24px;font-weight: bold;line-height: 41px;">高企测评</div>
        <div style="font-size: 16px;line-height:33px;margin:20px 0;">
          国家高新技术企业是中国高科技企业最高荣誉之一，获评“国家高新技术企业”的公司不仅能收获国家级资质认证硬招牌取得丰厚的资金补贴还可畅享税收优惠等政策福利。
          <span style="color:#2283FF">作为现阶段主打特色服务国家高新技术企业认定测评功能已全新上线！</span>
        </div>
        <div class="go-exam-btn-g" @click="goExam">立即体验</div>
        <div style="font-size: 16px;margin:20px 0;">【更多测评】农业高新企业测评、小巨人企业测评……即将开放！</div>
      </div>
      <img src="@/assets/image/index/exam-sys-3.png" style="height:257px" />
    </div>
    <div style="margin:50px 0">
      <div style="font-size: 30px;color:#333333;text-align:center;margin-bottom:40px">成功案例</div>
      <div class="flex-between" style="margin-top:50px;box-shadow: 0 6px 20px -6px rgba(0,0,0,.1);">
        <div class="exam-succ-1">
          江苏省某制造型企业，成立时间为2014年12月，有自主知识产权，该公司2016年可加计的研发费用100万，2017年可加计的研发费用为300万元，2017年应纳税所得额为400万。
          <div style="color:#2283FF">其可获得相关的税收优惠和科技项目支持为：</div>
        </div>
        <div class="exam-succ-2" style="padding:30px 0 30px 20px;width: 50%;">
          <div class="flex-align-center" style="margin-bottom: 20px;">
            <img src="@/assets/image/index/exam-succ-icon-1.png" style="width:34px" />
            <div class="exam-succ-title">税收政策</div>
          </div>
          <div class="exam-succ-item">
            <div class="exam-dot-big"></div>
            <span>按照正常25%所得税税率 所得税是：<span style="font-weight:bold">400*25%=100万；</span></span>
          </div>
          <div class="exam-succ-item">
            <div class="exam-dot-big"></div>
            <span>既是高新技术企业，感召高新技术企业研发费用175%加计扣除所得税是：<span style="font-weight:bold">(400-300*75%)*15%=26.25万；</span></span>
          </div>
          <div class="exam-succ-item">
            <div class="exam-dot-big" style="background:#FF7800"></div>
            <span style="color:#FF7800">通过认定后，享受高新技术企业优惠税率当年可节企业所得税40万；研发费用进行加计扣除后，还可节企业所得税26.25万，累计节企业所得税66.25万。</span>
          </div>
          <div class="flex-align-center" style="margin: 20px 0;">
            <img src="@/assets/image/index/exam-succ-icon-2.png" style="width:34px" />
            <div class="exam-succ-title">科技项目支持</div>
          </div>
          <div class="exam-succ-item">
            <div class="exam-dot-big"></div>
            <span>高新技术企业奖励：主流城市50-200万不等；</span>
          </div>
          <div class="exam-succ-item">
            <div class="exam-dot-big"></div>
            <span>研发奖补项目：按照研发费用加计扣除增长10%予以补助；</span>
          </div>
          <div class="exam-succ-item">
            <div class="exam-dot-big"></div>
            <span>科技类项目优先立项，另有不同数额直接现金奖励。</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import computer from '@/assets/image/index/computer.png'
import arrow2 from '@/assets/image/index/arrow2.png'
import lazy from '@/assets/image/index/lazy.png'
import cqzk from '@/assets/image/index/cqzk.png'
import csbg from '@/assets/image/index/csbg.png'
import glzk from '@/assets/image/index/glzk.png'
import jbxx from '@/assets/image/index/jbxx.png'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      token: 'userStore/token',
      userName: 'userStore/userName',
      userId: 'userStore/userId',
      enterpriseState: 'userStore/enterpriseState'
    }),
    border_width() {

      return (document.body.clientWidth - 1200) / 2 + 'px'
    }
  },
  name: 'ExaminationIndex',
  data() {
    return {
      cqzk: cqzk,
      csbg: csbg,
      glzk: glzk,
      jbxx: jbxx,
      computer: computer,
      arrow2: arrow2,
      lazy: lazy
    }
  },
  methods:{
    goExam(){
      if(this.enterpriseState !== 'CERTIFIED'){
        this.$router.push({
          path:'/enterprise/EnterpriseCertification'
        })
      }else{
        this.$router.push({
          path:'/enterprise/enterprise-physical-examination'
        })
      }

    }
  }
}

</script>

<style scoped lang="scss">
.bottom_div {
  height: 30px;
  line-height: 30px;
}

.sszc {
  width: 110px;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  background-image: linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: -webkit-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: -ms-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  margin-bottom: 20px;
}

.anli {
  background-position: center;
  background-image: url("~@/assets/image/index/anli.png");
}

.gqcp span {
  display: block;
  width: 480px;
  margin-bottom: 20px;
}

.qytj span {
  display: block;
}

.bxlc {
  display: flex;
}

.bxlc > div {
  height: 230px;
  width: 240px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.bxlc > span {
  padding: 0 25px;
  width: 80px;
  height: 230px;
  line-height: 230px;
  color: #406DDD;
  font-weight: bolder;
}

.qytj span:first-child {
  color: #79EB94;
  margin-left: 200px;
  margin-top: 50px;
  border: 1px solid #79EB94;
  font-weight: bold;
  width: 135px;
  padding: 5px 5px;
  border-radius: 4px;
}

.qytj span:last-child {
  background-image: -moz-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: -webkit-linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  background-image: linear-gradient(180deg, rgb(214, 245, 29) 0%, rgb(83, 253, 206) 100%);
  border-radius: 20px;
  font-size: 30px;
  line-height: 53px;
  width: 186px;
  height: 53px;
  color: rgb(41, 74, 212);
  margin: 0 auto;
  margin-top: 10px;

}

.qytj span:nth-child(4) {
  margin-top: 20px;
  font-size: 38px;
  color: white;
  font-weight: bold;
}

.qytj span:nth-child(3) {
  margin-top: 20px;
  font-size: 35px;
  font-weight: bold;
  color: rgb(41, 74, 212);
}

.qytj span:nth-child(2) {
  font-size: 60px;
  color: white;
  font-weight: bold;
}

.center {
  width: 1200px;
  height: 510px;

  margin: 0 auto;
}

.div_center {
  height: 510px;
  width: 100%;
  padding-top: 50px;
  justify-content: center;
  margin: 0 auto;
  background-image: url("~@/assets/image/index/examIndex.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.go-exam-btn ,.go-exam-btn-g{
  width: 130px;
  height: 38px;
  background: linear-gradient(90deg, rgba(15, 98, 223, 0.9) 0%, rgba(30, 168, 255, 0.9) 100%);
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  &.btn-1 {
    position: absolute;
    top:172px;
    left: 57px;
    background: none;
  }
}

.go-exam-btn-g:hover{
  background: linear-gradient(90deg, rgba(15, 98, 223, 1) 0%, rgba(30, 168, 255, 1) 100%);

}

.exam-banner{
  width: 100%;
  height: 250px;
  background-position: center;
  background-image: url("~@/assets/image/index/exam-banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.exam-dot {
  font-size: 24px;
  color:#188BF2;
  position: relative;
  top: -27px;
  letter-spacing: 5px;
}
.exam-dot-big{
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background:#41B0FB;
  margin:0 10px;
  display: inline-block;
}
.exam-step-txt {
  font-size: 16px;
  color: #333333;
  margin-top:20px
}
.exam-sys {
  width:599px;
  margin-top:53px;
  background-position: 405px 0;
  background-image: url("~@/assets/image/index/exam-sys-2.png");
  background-repeat: no-repeat;
}
.exam-succ-1 {
  padding:130px 88px;
  background-position: center;
  background-image: url("~@/assets/image/index/exam-succ.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 50%;
  line-height: 40px;
  font-size: 16px;
}
.exam-succ-title {
  color: #2283FF;
  font-size: 18px;
  margin-left: 13px;
}
.exam-succ-item{
  margin-top: 5px;
  line-height: 30px;
}
</style>
